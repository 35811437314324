import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/functions';
import 'firebase/compat/analytics';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY ?? 'AIzaSyBJG9us-LG8jB36of3efStaNXHyyWrnpdY',
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN ?? 'solutionworks-23e0f.firebaseapp.com',
    projectId: process.env.REACT_APP_PROJECT_ID ?? 'solutionworks-23e0f',
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET ?? 'solutionworks-23e0f.appspot.com',
    messagingSenderId: process.env.REACT_APP_MESSAGE_SENDER_ID ?? '491767278379',
    appId: process.env.APP_ID ?? '1:491767278379:web:ea675a787088bcf853f36e',
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const db = firebase.firestore();
export const functions = firebase.functions();

if (window.location.href.includes('http://localhost')) {
    auth.useEmulator('http://localhost:8302');
    functions.useEmulator('localhost', 8301);
    db.useEmulator('localhost', 8300);
}

export { firebase };
