import React, { useState, createContext, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { auth, db } from '../firebase';

const defaultFirebaseContext = {
    user: null,
};

export const AuthContext = createContext(defaultFirebaseContext);

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);

    const [tech, setTech] = useState(null);
    const [isInitialized, setIsInitialized] = useState(false);

    const [userInitialized, setUserInitialized] = useState(false);

    const collectionPrefix = useMemo(() => (user ? `/regions/${user.claims.regionId}` : null), [user]);
    const jobsRef = useMemo(
        () => (collectionPrefix ? db.collection(`${collectionPrefix}/jobs`) : null),
        [collectionPrefix],
    );
    const techsRef = useMemo(
        () => (collectionPrefix ? db.collection(`${collectionPrefix}/techs`) : null),
        [collectionPrefix],
    );
    const shopsRef = useMemo(
        () => (collectionPrefix ? db.collection(`${collectionPrefix}/shops`) : null),
        [collectionPrefix],
    );

    useEffect(() => {
        return auth.onAuthStateChanged(async (u) => {
            console.log('is there a u', u);
            if (u) {
                try {
                    const idTokenResult = await u.getIdTokenResult();
                    setUser({
                        ...u,
                        claims: idTokenResult.claims,
                    });
                } catch (error) {
                    console.log('error!!', error);
                    setUser(null);
                }
            } else {
                setUser(null);
            }
            setUserInitialized(true);
        });
    }, []);

    useEffect(() => {
        if (userInitialized) {
            if (user?.claims?.regionId) {
                const uid = user.uid || user.claims?.user_id;
                console.log('user.claims.regionId', user.claims.regionId, 'user.uid', user);
                return db
                    .collection(`/regions/${user.claims.regionId}/techs`)
                    .doc(uid)
                    .onSnapshot((techDoc) => {
                        console.log('techdoc', techDoc, techDoc.exists);
                        if (techDoc.exists) {
                            setTech({
                                ...techDoc.data(),
                                id: techDoc.id,
                            });
                            setIsInitialized(true);
                        } else {
                            console.log('prematurely signing out');
                            auth.signOut();
                        }
                    });
            }

            setTech(null);
            setIsInitialized(true);

            if (user) {
                auth.signOut();
            }
        }
        return () => {};
    }, [user, userInitialized]);

    const techId = useMemo(() => tech?.id, [tech]);

    return (
        <AuthContext.Provider
            value={{
                user,
                tech,
                techId,
                initialized: isInitialized,
                collectionPrefix,
                jobsRef,
                techsRef,
                shopsRef,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

UserProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
