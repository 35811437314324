import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import ForgotPasswordModal from './ForgotPasswordModal';
import { auth } from '../../firebase';
import logo from '../../Assets/Images/logo.png';

const LoginPage = () => {
    const [passwordModal, setPasswordModal] = useState(false);
    const closePasswordModal = () => {
        setPasswordModal(false);
    };

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);

    const signInWithEmailAndPasswordHandler = (event, mail, pass) => {
        event.preventDefault();
        auth.signInWithEmailAndPassword(mail, pass).catch((errors) => {
            setError(errors.message.split('.')[0]);
        });
    };

    const onChangeHandler = (setFunc) => (evt) => setFunc(evt.currentTarget.value);

    return (
        <div className="bg-cover min-h-screen bg-gradient-to-b from-black to-blue-500">
            <div className="flex flex-col">
                <div className="mt-32 text-center mx-auto">
                    <img src={logo} alt="Logo" className="text-center mx-auto w-8/12" />
                    <p className="text-lg font-medium text-center text-white mt-4">Technician Scheduling Made Easy</p>
                </div>
                <div className="text-center mt-16">
                    {error !== null && (
                        <div className="py-4 bg-primaryTheme w-full text-white text-center mb-3">{error}</div>
                    )}
                    <form
                        className="flex-col"
                        onSubmit={(event) => {
                            signInWithEmailAndPasswordHandler(event, email, password);
                        }}
                    >
                        <div className="my-4">
                            <input
                                type="email"
                                name="userEmail"
                                value={email}
                                onChange={onChangeHandler(setEmail)}
                                placeholder="Email"
                                className="px-3 py-3 placeholder-gray-600 text-gray-800 bg-gray-100 rounded focus:shadow-outline focus:outline-none w-72"
                            />
                        </div>
                        <div className="my-4">
                            <input
                                type="password"
                                name="userPassword"
                                value={password}
                                onChange={onChangeHandler(setPassword)}
                                placeholder="Password"
                                className="px-3 py-3 placeholder-gray-600 text-gray-800 bg-gray-100 rounded focus:shadow-outline focus:outline-none w-72"
                            />
                        </div>
                        <div className="mt-8">
                            <button
                                type="submit"
                                className="bg-primaryTheme hover:bg-blue-900 text-white font-bold py-2 px-4 rounded w-72"
                            >
                                Login
                            </button>
                        </div>
                    </form>
                    <div className="mt-4 text-white">
                        <button
                            className="hover:text-gray-900 cursor-pointer focus:outline-none"
                            onClick={() => setPasswordModal(true)}
                            type="button"
                        >
                            Reset Password
                        </button>
                    </div>
                    <div className="my-4 text-white">
                        <p>
                            <NavLink to="/signup">Not registered yet?</NavLink>
                        </p>
                    </div>
                </div>
            </div>
            {passwordModal ? (
                <>
                    <ForgotPasswordModal onClose={closePasswordModal} />
                </>
            ) : null}
        </div>
    );
};
export default LoginPage;
