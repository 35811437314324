import React, { useCallback, FunctionComponent, useContext, useEffect, useState } from 'react';
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_green.css';
import { format, addDays, addHours, setMinutes, addMinutes } from 'date-fns';
import { AuthContext } from '../../../Providers/UserProvider';
import LoaderComponent from '../../../Shared/Loader';
import { db } from '../../../firebase';

interface Props {
    onClose: () => void;
}

const CreateJobModal: FunctionComponent<Props> = (Props) => {
    const { shopsRef, collectionPrefix, techId } = useContext(AuthContext);

    const closeModal = useCallback(() => {
        Props.onClose();
    }, [Props]);

    const [validatingRealTime, setValidatingRealTime] = useState(false);

    const [locations, setLocations] = useState<any>([]);

    const [showLoader, setShowLoader] = useState(false);

    const orderSchema = yup.object({
        contact: yup.string().required('This input is required'),
        customerName: yup.string().required('This input is required'),
        type: yup.string().required('This input is required'),
        damage: yup.string().required('This input is required'),
        date: yup.string().required('This input is required'),
        vehicle: yup.object({
            year: yup.number().required('This input is required'),
            make: yup.string().required('This input is required'),
            model: yup.string().required('This input is required'),
            insurance: yup.string().required('This input is required'),
        }),
        shop: yup.string().required('This input is required'),
    });

    const jobTypeOptions = [
        {
            value: 'Estimate',
            title: 'Estimate',
            description: 'This job is an estimate',
        },
        {
            value: 'Repair',
            title: 'Repair',
            description: 'This job is a repair',
        },
    ];

    const damageTypeOptions = [
        {
            value: 'Hail',
            title: 'Hail',
            description: 'This job is for hail damage',
        },
        {
            value: 'Minor Damage',
            title: 'Minor Damage',
            description: 'This job is for minor damage',
        },
    ];

    const dropoff = [
        {
            value: 'Currently dropped',
            title: 'Currently dropped',
        },
        {
            value: 'Will be dropped',
            title: 'Will be dropped',
        },
        {
            value: 'Not dropping',
            title: 'Not dropping',
        },
    ];

    useEffect(() => {
        setShowLoader(true);
        return shopsRef.onSnapshot((querySnapshot) => {
            const arrayShops: any[] = [];
            querySnapshot.forEach((documentSnapshot) => {
                let newShops = documentSnapshot.data();
                newShops = { ...newShops, id: documentSnapshot.id };
                arrayShops.push(newShops);
            });
            setShowLoader(false);
            setLocations(arrayShops);
        });
    }, [shopsRef]);

    const handleCalendarChange = (formik, [date]) => {
        if (!date) {
            formik.setFieldValue('date', '');
            return;
        }
        const dateValue = format(date, 'yyyy-MM-dd HH:mm');
        formik.setFieldValue('date', dateValue);
    };

    const getMinDate = () => {
        const curDate = new Date();
        const nextDate = addMinutes(
            addDays(curDate, 1),
            curDate.getMinutes() % 15 === 0 ? 0 : 15 - (curDate.getMinutes() % 15),
        );
        return format(nextDate, 'yyyy-MM-dd HH:mm');
    };

    return (
        <>
            <LoaderComponent showLoader={showLoader} />
            <div className="fixed w-full h-full top-0 left-0 flex z-50 ">
                <div
                    className="fixed w-full overflow-y-scroll bg-details-background bg-cover pb-12"
                    style={{ height: '100%' }}
                >
                    <div className="fixed justify-start p-2 border-b border-solid border-gray-300 bg-gray-100 w-full">
                        <button
                            className="text-blue-500 background-transparent font-bold text-sm outline-none focus:outline-none"
                            type="button"
                            onClick={closeModal}
                        >
                            <FontAwesomeIcon className="text-base mr-1" icon={faChevronLeft} />
                            <span>Back</span>
                        </button>
                    </div>
                    <div className="pt-14 container mx-auto h-auto pb-4 text-center">
                        <div className="text-lg font-medium px-3">Add Job</div>
                        <Formik
                            initialValues={{
                                type: '',
                                damage: '',
                                date: format(addHours(setMinutes(addDays(new Date(), 1), 0), 1), 'yyyy-MM-dd HH:mm'),
                                shop: '',
                                customerName: '',
                                contact: '',
                                vehicle: {
                                    year: '',
                                    make: '',
                                    model: '',
                                    insurance: '',
                                },
                                assignee: techId,
                                notes: '',
                                status: 'pending',
                                invoiced: false,
                                waiting: false,
                                dropped: 'Currently dropped',
                            }}
                            validateOnBlur={false}
                            validateOnChange={validatingRealTime}
                            onSubmit={(values) => {
                                db.collection(`${collectionPrefix}/jobs`)
                                    .add(values)
                                    .then(() => {
                                        closeModal();
                                    });
                            }}
                            validationSchema={orderSchema}
                        >
                            {(formik) => (
                                <>
                                    <div>
                                        <div className="my-4">
                                            <select
                                                required
                                                className="px-3 py-3 placeholder-gray-600 text-gray-800 bg-gray-100 rounded focus:shadow-outline focus:outline-none w-72"
                                                onChange={(event) => formik.setFieldValue('type', event.target.value)}
                                            >
                                                <option className="placeholder-gray-600" value="">
                                                    Select job type
                                                </option>
                                                {jobTypeOptions.map((type) => (
                                                    <option key={type.value} value={type.value}>
                                                        {type.title}
                                                    </option>
                                                ))}
                                            </select>
                                            {formik.errors.type && (
                                                <p className="text-red-500 text-xs italic mt-2">{formik.errors.type}</p>
                                            )}
                                        </div>
                                        <div className="my-4">
                                            <select
                                                required
                                                className="px-3 py-3 placeholder-gray-600 text-gray-800 bg-gray-100 rounded focus:shadow-outline focus:outline-none w-72"
                                                onChange={(event) => formik.setFieldValue('damage', event.target.value)}
                                            >
                                                <option className="placeholder-gray-600" value="">
                                                    Select damage type
                                                </option>
                                                {damageTypeOptions.map((damageType) => (
                                                    <option key={damageType.value} value={damageType.value}>
                                                        {damageType.title}
                                                    </option>
                                                ))}
                                            </select>
                                            {formik.errors.damage && (
                                                <p className="text-red-500 text-xs italic mt-2">
                                                    {formik.errors.damage}
                                                </p>
                                            )}
                                        </div>
                                        <div className="my-4">
                                            <Flatpickr
                                                data-enable-time
                                                value={formik.values.date}
                                                options={{
                                                    minuteIncrement: 15,
                                                    altInput: true,
                                                    altFormat: 'M j\\, Y h\\:i K',
                                                    dateFormat: 'Y\\-m\\-d\\ H\\:i',
                                                    minDate: getMinDate(),
                                                }}
                                                onChange={(event) => handleCalendarChange(formik, event)}
                                                className="px-3 py-3 placeholder-gray-600 text-gray-800 bg-gray-100 rounded focus:shadow-outline focus:outline-none w-72"
                                                name="date"
                                            />
                                            {formik.errors.date && (
                                                <p className="text-red-500 text-xs italic mt-2">{formik.errors.date}</p>
                                            )}
                                        </div>
                                        <div className="my-4">
                                            <select
                                                required
                                                className="px-3 py-3 placeholder-gray-600 text-gray-800 bg-gray-100 rounded focus:shadow-outline focus:outline-none w-72"
                                                onChange={(event) => formik.setFieldValue('shop', event.target.value)}
                                            >
                                                <option className="placeholder-gray-600" value="">
                                                    Select Shop
                                                </option>
                                                {locations.map((shopInfo) => (
                                                    <option key={shopInfo.id} value={shopInfo.id}>
                                                        {shopInfo.name}
                                                    </option>
                                                ))}
                                            </select>
                                            {formik.errors.shop && (
                                                <p className="text-red-500 text-xs italic mt-2">{formik.errors.shop}</p>
                                            )}
                                        </div>
                                        <div className="my-4">
                                            <input
                                                required
                                                type="text"
                                                name="customerName"
                                                id="customerName"
                                                placeholder="Customer Name"
                                                onChange={(event) =>
                                                    formik.setFieldValue('customerName', event.target.value)
                                                }
                                                className="px-3 py-3 placeholder-gray-600 text-gray-800 bg-gray-100 rounded focus:shadow-outline focus:outline-none w-72"
                                            />
                                            {formik.errors.customerName && (
                                                <p className="text-red-500 text-xs italic mt-2">
                                                    {formik.errors.customerName}
                                                </p>
                                            )}
                                        </div>
                                        <div className="my-4">
                                            <input
                                                required
                                                type="text"
                                                name="contact"
                                                id="contact"
                                                placeholder="Primary Contact"
                                                onChange={(event) =>
                                                    formik.setFieldValue('contact', event.target.value)
                                                }
                                                className="px-3 py-3 placeholder-gray-600 text-gray-800 bg-gray-100 rounded focus:shadow-outline focus:outline-none w-72"
                                            />
                                            {formik.errors.contact && (
                                                <p className="text-red-500 text-xs italic mt-2">
                                                    {formik.errors.contact}
                                                </p>
                                            )}
                                        </div>
                                        <div className="my-4 text-left mx-auto px-3 py-3 placeholder-gray-600 text-gray-800 bg-gray-100 rounded focus:shadow-outline focus:outline-none w-72">
                                            <span>
                                                Invoiced:
                                                <Field className="ml-3" type="checkbox" name="waiting" />
                                            </span>
                                        </div>
                                        <div className="my-4 text-left mx-auto px-3 py-3 placeholder-gray-600 text-gray-800 bg-gray-100 rounded focus:shadow-outline focus:outline-none w-72">
                                            <span>
                                                Customer waiting:
                                                <Field className="ml-3" type="checkbox" name="invoiced" />
                                            </span>
                                        </div>
                                        <div className="my-4">
                                            <input
                                                type="text"
                                                name="year"
                                                id="year"
                                                placeholder="Vehicle Year"
                                                onChange={(event) =>
                                                    formik.setFieldValue('vehicle.year', event.target.value)
                                                }
                                                className="px-3 py-3 placeholder-gray-600 text-gray-800 bg-gray-100 rounded focus:shadow-outline focus:outline-none w-72"
                                            />
                                            {formik.errors.vehicle?.year && (
                                                <p className="text-red-500 text-xs italic mt-2">
                                                    {formik.errors.vehicle.year}
                                                </p>
                                            )}
                                        </div>
                                        <div className="my-4">
                                            <input
                                                type="text"
                                                name="make"
                                                id="make"
                                                placeholder="Vehicle Make"
                                                onChange={(event) =>
                                                    formik.setFieldValue('vehicle.make', event.target.value)
                                                }
                                                className="px-3 py-3 placeholder-gray-600 text-gray-800 bg-gray-100 rounded focus:shadow-outline focus:outline-none w-72"
                                            />
                                            {formik.errors.vehicle?.make && (
                                                <p className="text-red-500 text-xs italic mt-2">
                                                    {formik.errors.vehicle.make}
                                                </p>
                                            )}
                                        </div>
                                        <div className="my-4">
                                            <input
                                                type="text"
                                                name="model"
                                                id="model"
                                                placeholder="Vehicle Model"
                                                onChange={(event) =>
                                                    formik.setFieldValue('vehicle.model', event.target.value)
                                                }
                                                className="px-3 py-3 placeholder-gray-600 text-gray-800 bg-gray-100 rounded focus:shadow-outline focus:outline-none w-72"
                                            />
                                            {formik.errors.vehicle?.model && (
                                                <p className="text-red-500 text-xs italic mt-2">
                                                    {formik.errors.vehicle.model}
                                                </p>
                                            )}
                                        </div>
                                        <div className="my-4">
                                            <input
                                                type="text"
                                                name="insurance"
                                                id="insurance"
                                                placeholder="Vehicle Insurance"
                                                onChange={(event) =>
                                                    formik.setFieldValue('vehicle.insurance', event.target.value)
                                                }
                                                className="px-3 py-3 placeholder-gray-600 text-gray-800 bg-gray-100 rounded focus:shadow-outline focus:outline-none w-72"
                                            />
                                            {formik.errors.vehicle?.insurance && (
                                                <p className="text-red-500 text-xs italic mt-2">
                                                    {formik.errors.vehicle.insurance}
                                                </p>
                                            )}
                                        </div>
                                        <div className="my-4">
                                            <select
                                                className="px-3 py-3 placeholder-gray-600 text-gray-800 bg-gray-100 rounded focus:shadow-outline focus:outline-none w-72"
                                                onChange={(event) =>
                                                    formik.setFieldValue('dropped', event.target.value)
                                                }
                                            >
                                                <option className="placeholder-gray-600" value="">
                                                    Vehicle Dropoff
                                                </option>
                                                {dropoff.map((dropoffType) => (
                                                    <option key={dropoffType.value} value={dropoffType.value}>
                                                        {dropoffType.title}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="my-4">
                                            <input
                                                type="text"
                                                name="notes"
                                                id="notes"
                                                placeholder="Order Notes"
                                                onChange={(event) => formik.setFieldValue('notes', event.target.value)}
                                                className="px-3 py-3 placeholder-gray-600 text-gray-800 bg-gray-100 rounded focus:shadow-outline focus:outline-none w-72"
                                            />
                                        </div>
                                    </div>
                                    <button
                                        type="button"
                                        className="bg-green-400 hover:bg-green-900 text-white font-bold py-6 px-4 w-full"
                                        onClick={() => {
                                            setValidatingRealTime(true);
                                            formik.submitForm();
                                        }}
                                    >
                                        Create Job
                                    </button>
                                </>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CreateJobModal;
